.linkbarcontainer {
  position: relative;
  border-top: 1px solid rgba(70, 67, 67, 0.274);
  border-bottom: 1px solid rgba(70, 67, 67, 0.274);
  /* height: 3rem; */
}
.link-container {
  /* height: 4rem; */

  padding-left: 10vw;
  padding-right: 10vw;
}
.link-item-container {
  /* height: 4rem; */

  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 2rem;
  padding-bottom: 4rem;
  /* display: flex; */
}
.linkbar ul {
  margin: 0px;
  font-size: 16px;
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: flex-end;
}

.linkbar ul li {
  /* position: relative; */
  /* margin-top: 0.8rem; */
  /* margin-bottom: 0.8rem; */

  /* border-right: 1px solid rgba(70, 67, 67, 0.274); */

  float: left;
  padding-right: 2.4rem;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  padding-left: 2.4rem;

  /* padding: 0.9rem 1.5rem; */
  text-align: center;
  display: table;
}

.link-border {
  border-right: 1px solid rgba(70, 67, 67, 0.274);
  padding-right: 1rem;
}
.linkbar ul li:hover .link-border {
  border-right: 1px solid rgba(70, 67, 67, 0);
}

.linkbar ul li a {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  color: #00729a;
  /* text-decoration: 1px solid black; */
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
  display: table-row;
}
.linkbar ul li a:hover {
  color: #00bbe3;
}

.dropdown {
  position: absolute;
  z-index: 10000;
  /* height: 300px; */
  width: 100vw;
  background-color: #e7e9ec;
  box-shadow: 0 30px 20px -20px rgba(128, 128, 128, 0.424);
  /* float: left; */
  /* top: 90%; */
  margin-top: 1.4rem;
  left: 50%;
  transform: translate(-50%, 0%);
  display: none;
  text-align: left;
}
.dropdown p {
  line-height: 1.5rem;
}
.dropdown ul {
  font-size: 14px;
  list-style: none;
  float: none;
  width: auto;
  display: block;
}
.dropdown ul li {
  font-size: 14px;
  list-style: none;
  float: none;
  width: auto;
  border-right: none;
  margin: 0;
  padding: 0;
  margin-bottom: 2rem;
  text-align: left;
  border-bottom: 1px dotted #00729a;
}

.dropdown ul li:hover {
  text-decoration-line: dotted;
  text-decoration-style: wavy;
  text-decoration-color: red;
  border-bottom: 1px solid #00bbe3;
}

.linkbar ul li:hover .dropdown {
  display: block;
}

.dropdownClose {
  display: none;
}
.link-item {
  /* margin-left: 1.1rem; */
  background-color: #e7e9ec;
}
.link-item:hover {
  background-color: #d0d0d1;
}

@media screen and (max-width: 1600px) {
  .link-container {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

button .close {
  font-weight: 100;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circular-button {
  position: relative;
  display: block;
  height: 3rem;
  width: 3rem;
  border: 0 solid #00bbe3;
  border-radius: 50%;
  background: #00bbe3;
}

.circular-button:active {
  border: none;
}
@media screen and (max-width: 850px) {
  .linkbarcontainer {
    display: none;
  }
}
