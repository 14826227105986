.profile-upload-overlay {
  /* display: none; */
  width: 150px;
  height: 150px;
  /* border-radius: 150px; */
  background-color: transparent;
  /* margin: 1px 6px; */
  position: absolute;
  bottom: 0;
  /* background-image: linear-gradient(90deg, blue, blue); */
  clip-path: circle(50%);
  left: 0;
  /* cursor: pointer; */
}

.profile-upload-overlay:hover .profile-overlay-image {
  /* display: block; */
  /* background-color: rgba(48, 48, 68, 0.253); */
  opacity: 1 !important;
}

.profile-overlay-image {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
  width: 150px !important;
  background-color: rgba(207, 207, 207, 0.664) !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  height: 40px !important;
  opacity: 0 !important;
}

.square-upload-overlay {
  width: 320px;
  height: 180px;

  background-color: transparent;

  position: absolute;
  bottom: 0;

  left: 0;
}

.square-upload-overlay:hover .square-overlay-image {
  opacity: 1 !important;
}

.square-overlay-image {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
  width: 320px !important;
  background-color: rgba(207, 207, 207, 0.664) !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  height: 50px !important;
  opacity: 0 !important;
}
.mytooltip {
  display: inline;
  /* background-color: red !important; */
  position: absolute;
  bottom: 0%;
  /* right: 0%; */
  /* width: 90px !important; */
  cursor: pointer;
  /* padding: 20px 20px;
  padding-bottom: 0px; */
  margin-left: 20px;
}
.tooltiptext {
  visibility: hidden;
  width: 130px;

  background-color: rgba(182, 182, 182, 0.329);
  color: black;
  text-align: left;
  border-radius: 6px;
  padding: 5px 10px;
  padding-left: 17px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  clip-path: polygon(
    7% 0%,
    100% 0%,
    100% 100%,
    7% 100%,
    7% 55%,
    0% 50%,
    7% 45%
  );

  /* Position the tooltip */
  position: absolute;
  top: 70%;
  right: -100%;
  transform: translateY(-50%);
  z-index: 1;
}

.mytooltip:hover .tooltiptext {
  visibility: visible;
}

.basicinfo-square-image {
  width: 320px;
  height: 180px;
}
.upload-btn {
  position: absolute;

  top: 50%;
  transform: translate(0, -50%);
}

@media screen and (max-width: 1100px) {
  .basicinfo-square-image {
    width: 160px;
    height: 90px;
  }
  .square-overlay-image {
    width: 160px !important;
  }
  .square-upload-overlay {
    width: 160px;
    height: 90px;
  }
}
