.tnc-list {
  margin-bottom: 5rem;
}
.tnc-list ol li {
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 1.2rem;
  margin-top: 1.2rem;
  display: block;
}
.tnc-list ol {
  counter-reset: item;
}

.tnc-list ol li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}
.d-inline {
  display: inline !important;
}

.tnc-list-description {
  display: block;
  font-size: 1.2rem;
  text-align: justify;
  font-weight: normal !important;
  margin-top: 0.5rem;
  line-height: 1.9rem;
}

.tnc-list ol ul {
  padding-left: 40px;
}
.tnc-list ol ul li {
  font-weight: normal;
  display: block;
  line-height: 1.7rem;
  font-size: 1.2rem;
}

.tnc-list ol ul li:before {
  content: "● ";
  counter-increment: item-1;
}
