.col-lg-5 a {
  text-decoration: none;
  color: black;
}

.contactus-item {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0px 0px 9px -6px rgba(0, 0, 0, 1);
  cursor: pointer;
}
.contactus-item:active {
  box-shadow: none;
}

.contactus-item-icon {
  /* display: inline-block;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: #00a1cc;
  color: white;*/
  position: relative;
  text-align: center;
  /* font-size: 2.5rem; */
}

.contactus-item-icon i {
  /* position: absolute;
  bottom: -10%;
  left: 30%; */
  /* transform: translate(-50%, -50%); */
  /* font-size: 2rem; */
}

.contactus-item-heading {
  font-size: 1.3rem;
  color: #00a1cc;
  margin-bottom: 0.5rem;
  font-weight: 600;
  padding-left: 1.5rem;
}

.contactus-item-text {
  font-size: 1.1rem;
  padding-left: 1.5rem;
}

.contactus-card {
  box-shadow: 0px 0px 9px -6px rgba(0, 0, 0, 0.78);
  /* border: 1px solid black; */
  padding-top: 2rem;
  padding-bottom: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
