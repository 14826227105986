.header-container {
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: 10vw;
  margin-right: 10vw;
  transition: all 0.3s;
}
.body-container {
  position: relative;
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-left: 10vw;
  margin-right: 10vw;
  padding-bottom: 10rem;
  transition: all 0.3s;
  display: block;
  /* border-bottom: 0.14rem dotted rgba(0, 0, 0, 0.561); */
}
.section-container:not(:last-child) {
  margin-top: 8rem;
  margin-bottom: 8rem;
  margin-left: 10vw;
  margin-right: 10vw;
  padding-bottom: 8rem;
  transition: all 0.3s;
  display: block;
  border-bottom: 0.14rem dotted rgba(0, 0, 0, 0.561);
}
.section-container:last-child {
  margin-top: 8rem;
  margin-bottom: 8rem;
  margin-left: 10vw;
  margin-right: 10vw;
  padding-bottom: 8rem;
  transition: all 0.3s;
  display: block;
}

.footer-container {
  margin-left: 10vw;
  margin-right: 10vw;
  transition: all 0.3s;
}
.aboutus-section-container:first-of-type {
  /* background-color: blue; */
  /* margin-left: 24rem; */
  display: block;
  /* margin-top: 10rem; */
  /* height: 50rem; */
}
.aboutus-section-container:not(:first-child) {
  /* background-color: red; */
  padding-top: 5rem;
  /* margin-left: 24rem; */
  margin-top: 5rem;
  /* height: 50rem; */
}
.map-container {
  margin-top: 10rem;
  margin-bottom: 10rem;
  height: 35rem;
}

@media screen and (max-width: 1600px) {
  .header-container {
    margin-left: 5vw;
    margin-right: 5vw;
  }
  .section-container {
    margin-top: 5rem;
    margin-bottom: 5rem;
    margin-left: 5vw;
    margin-right: 5vw;
    padding-bottom: 5rem;
  }
  .body-container {
    margin-left: 5vw;
    margin-right: 5vw;
  }
}
@media screen and (max-width: 992px) {
  .contactus-card-container {
    padding: 0 !important;
  }
  .aboutus-section-container {
    margin-left: 0;
  }
  .aboutus-section-container:first-of-type {
    margin-left: 0;
  }
  .aboutus-section-container:not(:first-child) {
    margin-left: 0;
  }
}
