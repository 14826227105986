.consulting-subsection {
  /* padding-right: 5rem;
  margin-right: 5rem; */
  border-right-width: 1.5px;
  border-right-style: solid;
  border-color: rgba(0, 0, 0, 0.5);
  /* display: inline-block; */
}
/* .consulting-form-section {
  width: 100%;
  float: right;
  display: inline-block;
} */

button.appointment-btn {
  float: right;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 600;
  font-size: 17px;
  background-color: #00a1cc;
  color: #fff;
}
button.appointment-btn:hover {
  background-color: #fff;
  color: #0074e1;
}
@media screen and (max-width: 768px) {
  .col-md-5.consulting-subsection {
    border: none;
    margin-bottom: 2rem;
  }
}
h2.section-subtitle-center {
  display: block;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 600;
}
.section-paragraph-big-center {
  display: block;
  font-size: 20px;
  line-height: 2rem;
  text-align: center;
  font-weight: 400;
}

.section-emphasize {
  font-size: 18px;
  line-height: 3.5rem;
  letter-spacing: 1px;
  text-shadow: 1px 1px rgba(128, 128, 128, 0.5);
  font-weight: 400;
  /* margin-bottom: 2rem; */
}
.phone-button a {
  text-decoration: none;
  color: white;
  font-size: 1rem;
  padding: 1rem 2rem;
  border-radius: 3px;
  background-color: #00a1cc;
  /* background-image: linear-gradient(315deg, #1e3c72 0%, #2a5298 74%); */
  box-shadow: 05px 05px 10px grey;
  transition: all 0.5s;
}

.phone-button :hover {
  color: white;
}
.phone-button a:active {
  box-shadow: none;
}

@media screen and (max-width: 425px) {
  /* .phone-button a {
    padding: 1rem rem;
  } */
}
