.testimonial-card{
    padding: 1rem 2rem;
    text-align: center;
    background-color: #e7e9ec;
    line-height: 1.8rem;
    border-radius: 0.5rem;
    /* max-width: 50rem;
    height: 100%; */
}
.testimonial-card-primary{
    /* font-weight: bold; */
    font-style: italic;
    font-size: 1.1rem;
    word-spacing: 0.5rem;
}

.testimonial-card-writer{
    margin-top: 1rem;
    font-weight: bold;
    font-size: 1.5rem;
    word-spacing: 0.5rem;
}
