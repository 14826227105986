.loader-bg {
  background-color: #00bbe3 !important;
}
.payment-form {
  background-color: rgba(235, 239, 242, 1);
  border-radius: 8px;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2), 0 0 64px rgba(0, 0, 0, 0.05);
  display: grid;
  grid-row-gap: 32px;
  width: 550px;
  margin: 32px;
  padding: 32px;
  z-index: 1;
  margin-bottom: 7rem;
}

@media only screen and (max-width: 600px) {
  payment-form {
    width: 310px;
    margin: 5px;
    padding: 5px;
  }
}
.main-header {
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(0, 156, 227, 1) 0%,
    rgba(127, 215, 255, 1) 100%
  );
  border-radius: 8px 8px 0 0;
  color: white;
  display: flex;
  font-size: 32px;
  font-weight: bold;
  height: 96px;
  margin-left: -32px;
  margin-right: -32px;
  margin-top: -32px;
}
.main-header span {
  margin-left: 32px;
}

.product-info {
  background-color: rgba(211, 216, 222, 1);
  border-radius: 4px;
  color: rgba(19, 41, 63, 0.65);
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  padding: 24px 20px;
}
.divider {
  background-color: rgba(228, 231, 235, 1);
  height: 1px;
  margin-left: -32px;
  margin-right: -32px;
}

.info-row {
  font-size: 1.4rem;
  font-weight: bold;
}

.neo-button {
  background-color: rgba(235, 239, 242, 1);
  border: 1px solid rgba(211, 216, 222, 1);
  border-radius: 16px;
  color: rgba(19, 41, 63, 0.4);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 20px;
  transition: box-shadow 0.1s ease, border-color 0.1s ease, color 0.1s ease;
  width: 100%;
  color: rgba(0, 156, 227, 1);
}
.neo-button:focus,
.neo-button:hover {
  background: linear-gradient(
      115deg,
      rgba(100, 100, 100, 0.02),
      rgba(255, 255, 255, 0.5)
    ),
    rgba(235, 239, 242, 1);
  border: 1px solid rgba(235, 239, 242, 1);
  box-shadow: -10px -10px 20px rgba(249, 250, 251, 1),
    10px 10px 20px rgba(200, 204, 207, 1);
  color: rgba(0, 156, 227, 1);
  cursor: pointer;
  transition: box-shadow 0.4s ease, border-color 0.4s ease, color 0.4s ease;
}
.neo-button:active {
  box-shadow: -2px -2px 10px rgba(249, 250, 251, 1),
    2px 2px 5px rgba(200, 204, 207, 1);
  transition: box-shadow 0.1s ease, border-color 0.1s ease, color 0.1s ease;
}
