.auth-card {
  max-width: 34rem;
  width: 90%;
  height: 47rem;
  /* border: 1px solid black; */
  /* // backgroundImage: `url(${waveimg})`;
              // backgroundRepeat: " no-repeat"; */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.05);
  border-radius: 2%;
  margin-bottom: 10rem;
}

.signup-or-login {
  width: 50%;
  height: inherit;
  background-color: blue;
}

.card {
  flex-direction: row;
}
.auth-text {
  position: relative;
  display: inline-block;
  width: 40%;
  height: inherit;
}
.auth-text-heading {
  text-align: center;
  margin-top: 15rem;
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 2.8rem;
  color: #00a1cc;
}

/* .auth-img {
  display: inline-block;
  width: 60%;
  height: inherit;
}

.auth-img svg {
  object-fit: fill;
} */

.auth-card-avatar {
  margin-top: 3rem;
  position: relative;
}
.auth-card-avatar img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 17rem;
  height: auto;
}

.auth-form {
  position: relative;
  text-align: center;
  margin-top: 3rem;

  height: 5rem;
}
.auth-form form {
  margin-bottom: 01rem;
}
/* .auth-input-container { */
/* max-width: 24rem;
  width: 90%; */

/* margin: 2rem 5rem; */
/* position: relative; */
/* } */
.input-text {
  display: block;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  /* position: absolute; */
  /* height: 100%; */
  /* width: 100%; */
  /* top: -200%; */
}
.auth-input-container input {
  width: 70%;
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.329);
  outline: none;
  font-size: 1.3rem;
  /* text-align: center; */
  transition: all 0.3s;
}
.auth-input-container input:focus {
  /* text-align: left; */
  border-bottom: none;
  /* width: 90%; */
  border-bottom: 2px solid #00729a;
}
.auth-form button {
  text-decoration: none;
  border: none;
  background: none;
  width: 15rem;
  height: 3rem;
  border-radius: 15rem;
  background-color: #00a1cc;
  font-size: 1.3rem;
  color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.auth-form button:hover {
  border: 2px solid #00729a;
}

.auth-form button:active {
  border: none;
  outline: none;
  box-shadow: none;
}

.socialmedia-icon {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
  font-size: 3rem;
}

.socialmedia-icon-facebook {
  color: #3b5998;
  transition: all 0.3s;
}
.socialmedia-icon-facebook:hover {
  transform: scale(1.2, 1.2);
}
.socialmedia-icon-twitter {
  color: #00acee;
  transition: all 0.3s;
}

.socialmedia-icon-twitter:hover {
  transform: scale(1.2, 1.2);
}

.socialmedia-icon-google {
  color: #e4405f;
  transition: all 0.3s;
}

.socialmedia-icon-google:hover {
  transform: scale(1.05, 1.05);
}

.center-verify-card {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.auth-form .help-reg-btn {
  text-decoration: none;
  border: none;
  background: none;
  width: auto;
  height: auto;
  border-radius: 0;
  background-color: transparent;
  font-size: 1rem;
  color: #4183c4;
  box-shadow: none;
}
.auth-form .help-reg-btn:after {
  content: "";
  display: block;
  border-bottom: 1px solid #4183c4;
  transform: scaleX(0);
  transition: transform 300ms ease-in-out;
}
.auth-form .help-reg-btn:hover {
  border: none;
  /* transform: scaleX(1); */
}
.auth-form .help-reg-btn:hover:after {
  transform: scaleX(1);
  transform-origin: 0% 50%;
}
