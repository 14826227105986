.mynavbar-top {
  padding-top: 0.5rem;

  padding-bottom: 0.5rem;
  font-size: 14px;
  color: black;
  /* border-bottom: 1px solid rgba(70, 67, 67, 0.274); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: ; */
}
.mynavbar-top-primary {
  display: flex;
  justify-content: space-evenly;
  align-items: right;
}
.mynavbar-top-primary-item:not(:last-child) {
  padding-right: 1.5rem;
  margin-right: 1.5rem;
  border-right: 1px solid rgba(70, 67, 67, 0.274);
}

.mynavbar-top-button a.button {
  /* display: inline-block;
  padding: 0.2em 0.5em;
  margin: 0 0.1em 0.1em 0;
  border-radius: 2em;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.452);
  text-decoration: none;
  box-sizing: border-box;
  color: #fff;
  background-color: #0074e100;
  text-align: center;
  letter-spacing: 1px;
  font-size: 16px;
  transition: all 0.5s; */
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  padding: 9px 25px;
  background-color: #00a1cc;
  border: 2px solid #c0b596;
  border-radius: 47px;
  text-transform: capitalize;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.mynavbar-top-button a.button:hover {
  background-color: #00729a;
}

.primary-navlink ul {
  float: right;
  justify-content: space-evenly;
  list-style: none;
  margin-top: 5px;
  font-size: 15px;
}
.primary-navlink ul li {
  display: inline-block;
}
.primary-navlink ul li a {
  position: relative;
  color: black;
  text-decoration: none;
  /* margin: 0px 15px; */

  margin-left: 30px;

  transition: all 0.2s;
}

.primary-navlink ul li :hover {
  color: #00a1cc;
  border-bottom: 1px solid;
}

.nav-phone-box {
  /* float: right;
  margin-top: 5px; */
  /* 
  margin-left: 30px; */
  color: #dc7d00;
  display: inline-block;
}

.nav-phone-box i.icon {
  display: inline;
}
.nav-phone-text {
  font-size: 1.5rem;
  font-weight: 600;
}

.nav-search-box {
  margin-top: 10px;
}

.nav-search-box:hover {
  border-color: #00a1cc;
}

.login-btn {
  /* padding: 0.5rem 1.5rem;
  font-size: 16px;
  background: #00a1cc;
  color: white;
  border-radius: 1rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); */
  text-decoration: none;
  /* display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #fff; */
  /* padding: 2.4rem 0rem; */
  /* margin-left: 1rem; */
  /* margin-right: 1rem; */
  /* margin-left: 1.1rem; */
  background-color: #00a1cc;
  /* border: 2px solid #c0b596; */
  /* border-radius: 47px; */
  text-transform: capitalize;
  /* display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px; */
}

.login-btn:hover {
  background-color: #00729a;
  color: white;
}

.login-btn:active {
  box-shadow: none;
}

.mobile-nav {
  display: none;
}
.mobile-nav-link {
  position: fixed;
  top: 50%;
  left: 250%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  transition: all 0.8s;
}
.mobile-nav-link ul {
  margin: 0;
  padding: 0;
  text-align: center;
}
.mobile-nav-link li {
  text-decoration: none !important;
  list-style: none;
  color: white;
  padding-bottom: 2.3rem;
  font-size: 1.7rem;
}
#mobile-nav-toggle {
  display: none;
}

.mobile-nav-checkbox:checked ~ .mobile-nav-background {
  left: 0;
}
.mobile-nav-checkbox:checked ~ .mobile-nav-link {
  left: 50%;
}

.mobile-nav-button {
  position: fixed;
  top: 1.5rem;
  right: -5vw;
  z-index: 1500;
}
.mobile-nav-button i {
  cursor: pointer !important;
}
/* 00a1cc */
.mobile-nav-background {
  position: fixed;
  top: 0;
  left: 200%;
  background: rgb(0, 114, 154);
  background: linear-gradient(
    270deg,
    rgb(12, 133, 177) 0%,
    rgba(0, 161, 204, 1) 100%
  );
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  transition: all 0.8s;
  box-shadow: -10px 3px 24px -10px rgba(0, 0, 0, 0.61);
}

.sub-nav-menu {
  display: none;
}
.sub-nav-menu ul a {
  margin: 0;
  padding: 0;
}
.nav-hover-item {
  cursor: pointer;
  color: #1e70bf;
}
.nav-hover-item:hover .sub-nav-menu ul {
  /* float: none !important; */
  /* display: block !important; */

  flex-direction: column;
}
.nav-hover-item:hover .sub-nav-menu ul li {
  float: none !important;
  font-weight: bold;
  width: 100%;
  /* display: list-item !important; */
}
.sub-nav-menu ul li:hover {
  background-color: #d0d0d1;
}
.nav-hover-item:hover .sub-nav-menu {
  display: inline-block;
  position: absolute;
  background-color: #e7e9ec;
  margin-top: 2.489rem;
  margin-left: -5.6rem;
  z-index: 9999999;
  /* transform: translate(-50%, 23.5%); */
}
/*  */
@media screen and (max-width: 1100px) {
  .mynavbar-top-primary {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  .mobile-nav {
    display: block;
  }
  .mynavbar-top {
    width: 100vw;
    height: 5rem;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    padding-left: 5vw;
    padding-right: 5vw;
    box-shadow: 0px 1px 20px -10px rgba(0, 0, 0, 0.61);
  }
  .mynavbar-top-primary {
    display: none;
  }
  .mynavbar-top-button {
    display: none;
  }
  .mobile-nav-button {
    right: 5vw;
  }
}
