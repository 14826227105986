.required-field::after {
  content: "*";
  color: red;
}

.invalid-input {
  color: red;
  font-weight: bold;
}
.blue-will-button {
  background-color: #00a1cc !important;
  color: #fff !important;
}

.will-button {
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #000;
  padding: 9px 25px;
  background-color: transparent;
  border: 2px solid #c0b596;
  border-radius: 47px;
  text-transform: capitalize;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.will-next-button {
  padding: 0.5em 1.5em;
  border: none;
  /* margin: 0 0.3em 0.3em 0; */
  border-radius: 0.12em;

  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  transition: all 0.2s;
  box-shadow: -0px 0px 5px 0px rgba(0, 0, 0, 0.644);
  margin-top: 3.5rem;
  width: 100%;
  background-color: #00a1cc;
}
.will-next-button:hover {
  background-color: #00729a;
}

.will-button:hover {
  background-color: #00729a !important;
  color: white;
}
.will-button-selected {
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  padding: 9px 25px;
  background-color: #22ac38;
  border: 2px solid #c0b596;
  border-radius: 47px;
  text-transform: capitalize;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
  /* box-shadow: inset 0px 0px 10px -1px #00000078; */
}

.will-button-selected-with-hover {
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  padding: 9px 25px;
  background-color: #22ac38;
  border: 2px solid #c0b596;
  border-radius: 47px;
  text-transform: capitalize;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
  /* box-shadow: inset 0px 0px 10px -1px #00000078; */
}

.will-button-selected-with-hover:hover {
  background-color: #229034;
}

.will-component-slide-in {
  animation-name: slide-in;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}

@keyframes slide-in {
  from {
    margin-left: 100vw;
  }
  to {
    margin-left: 0;
  }
}

.modal-button {
  width: 50px;
  height: 50px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  background-color: #00a1cc;
  border-radius: 25px;
  border: none;
  box-shadow: -0px 0px 11px 0px rgba(0, 0, 0, 0.644);
}
.modal-button:hover {
  background-color: #00729a;
}

.float-button {
  position: fixed;
  bottom: 10rem;
  right: 5%;
}

.remove-form {
  text-decoration: none;
  background-color: transparent;
  border: 0;
  color: red;
}

.fixed-button {
  position: absolute;
  bottom: -15rem;
  /* right: -14%; */
  right: -26.5%;
}
.skip-card {
  border: 1px solid rgba(0, 0, 0, 0.164);
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  box-shadow: 0px 0px 9px -6px rgba(0, 0, 0, 1);
}

.ui.ordered.steps .step.active:before,
.ui.steps .active.step .icon {
  color: #00a1cc !important;
}

.profile-image input[type="file"] {
  -webkit-appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.loader-container {
  position: absolute;
  top: -90%;
  left: -20%;
  height: 110vh;
  width: 110vw;
}

/* [type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
}

[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1.25em;
  height: 1.25em;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: "\2713\0020";
  position: absolute;
  top: 0.15em;
  left: 0.22em;
  font-size: 1.3em;
  line-height: 0.8;
  color: #09ad7e;
  transition: all 0.2s;
  font-family: "Lucida Sans Unicode", "Arial Unicode MS", Arial;
}

[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
} */

.price-card {
  margin: 2rem 2rem;
  max-width: 22rem;
  width: 90%;
  height: 32rem;
  background-color: rgb(255, 254, 254);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.5s;
}

.price-card:hover {
  transform: scale(1.05) !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.05) !important;
}
.price-card-selected {
  transform: scale(1.05) !important;
  margin: 2rem 2rem;
  /* max-width: 22rem; */
  width: 22rem;

  height: 32rem;
  background-color: rgb(255, 254, 254);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.05) !important;
}
.price-card-head {
  position: relative;
  width: 100%;
  height: 10rem;
  font-size: 3rem;
  background-color: #00a1cc;
  color: #fff;
}
.price-card:hover .price-card-head {
  background-color: #22ac38;
}
.price-card-head-text {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  padding-top: 1rem;
}

.price-card-head-text-premium {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  padding-top: 4rem;
}
.price-card-head-popular-text {
  position: absolute;
  bottom: -16%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* f9a602 */
  background-color: #f9a602;
  padding: 0.1rem 1rem;
  border-radius: 1rem;
  font-size: 1rem;
}
.price-card-body {
  height: 22rem;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.price-card-body-text {
  font-size: 1.1rem;
  line-height: 1.5rem;
  /* word-spacing: 1rem; */

  font-weight: bold;
}

@media screen and (max-width: 1600px) {
  .fixed-button {
    position: absolute;
    bottom: -15rem;
    right: -14%;
    /* right: -26.5%; */
  }
  .loader-container {
    position: absolute;
    top: -90%;
    left: -8%;
    height: 110vh;
    width: 110vw;
  }
}
.divider {
  background: none !important;
}
.will-start-button {
  width: 48rem !important;
}
.will-start-info-text {
  width: 48rem !important;
  font-weight: bold;
  font-size: 1.3rem;
  padding: 2rem;
}

@media screen and (max-width: 768px) {
  .fixed-button {
    position: absolute;
    bottom: -15rem;
    right: -0%;
    /* right: -26.5%; */
  }
  .will-start-button {
    width: 22rem !important;
  }
  .will-start-info-text {
    width: 22rem !important;
  }
  .step-mini {
    padding: 0.5rem 0.5rem !important;
    font-size: 0.8rem !important;
  }
  .step-mini-container {
    left: -4%;
    /* position: fixed;
    top: 10%; */
  }
  .tiny-step-group {
    font-size: 2rem !important;
  }
}

@media screen and (max-width: 350px) {
  .price-card {
    width: 19rem;
    /* height: 28rem; */
  }
}
