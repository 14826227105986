.ourServices-heading {
  text-align: center;
  font-size: 2.5rem;
  color: #00a1cc;
  margin-bottom: 4rem;
  font-weight: 600;
}
.ourServices-subheading {
  font-size: 1.12rem;
  line-height: 26px;

  text-align: justify;
  margin: 0 2.5rem;
  margin-bottom: 2rem;
  padding: 2rem 2rem;
}
.ourServices-subsection-heading {
  text-align: center;
  /* font-size: 2rem; */
  /* color: #00a1cc; */
  margin-bottom: 2rem;
  /* font-weight: 600; */
}
.ourServices-item-container {
  /* border-right: 1px solid black; */
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.472); */
  box-shadow: 0px 0px 9px -6px rgba(0, 0, 0, 1);
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 5rem;
}

.ourServicesCard {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  text-align: center;
  height: 100%;
  transition: all 0.3s;
}
.ourServicesCard:hover {
  background-color: #e7e9ec;
}

.ourServicesCard-heading {
  color: #00a1cc;
  margin-bottom: 1.2rem;
}

.ourServicesCard-heading-icon {
  /* display: block; */
  font-size: 5rem;
}

.ourServicesCard-heading-text {
  padding-top: 1rem;
  display: block;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2rem;
}

.ourServicesCard-description {
  font-size: 1.1rem;
  font-weight: 100;
  margin-bottom: 1rem;
}

.ourServicesCard-link span {
  font-size: 1.1rem;
  font-weight: 100;
  border-bottom: 1px dotted #00a1cc;
}

.ourServicesCard-link :hover {
  border-bottom: 1px solid #00a1cc;
}

/* 
text-decoration-line: underline;
  text-decoration-style: dotted;
  text-decoration-color: red; */

@media screen and (max-width: 992px) {
  .ourServices-subheading {
    margin-left: 0;
    margin-right: 0;
  }
}
