.videosection {
  background: #00a1cc;
  color: white;
}
.videosection-text {
  background: #00a1cc;
  color: white;
  margin: 2rem 4rem;
  padding: 4rem 0rem;
}

.videosection-text-heading {
  font-size: 2.5rem;
  line-height: 1.16;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 2.5rem;
}
.videosection-text-description-normal {
  font-size: 1.1rem;
  font-weight: 300;
  display: inline-block;
  margin-bottom: 1.5rem;
}

.videosection-text-description-bold {
  font-size: 1.1rem;
  font-weight: 600;
  display: inline-block;
  text-align: justify;
  margin-bottom: 1.5rem;
  /* letter-spacing: -1px; */
}

.videosection-text-notice {
  font-size: 1.1rem;
  font-weight: 1000;
  display: inline-block;
  text-align: justify;
  margin-bottom: 1.5rem;
  /* letter-spacing: -1px; */
}

.videosection-text-button {
  text-decoration: none;
  display: block;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  width: 100%;
  text-align: center;
  transition: all 0.3s;
  background-color: azure;
  color: black;
  font-weight: 600;
}

.videosection-text-button:hover {
  box-shadow: 0px 30px 20px -20px rgb(128, 128, 128);
}
.video-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: block;
  /* padding-right: 100px; */
  background-color: black;
}
.video-container video {
  width: inherit;
  position: absolute;

  top: 50%;
  object-fit: fill;
  /* max-height: 32rem; */
  height: 100%;
  transform: translate(0, -50%);
}
.embed-responsive .embed-responsive-21by9 {
  border: none;
}

@media screen and (max-width: 720px) {
  .video-container video {
    top: 0;
    transform: translate(0, 0);
  }
}

.video-container img {
  width: inherit;
  position: absolute;

  top: 50%;
  object-fit: fill;
  /* max-height: 32rem; */
  height: 100%;
  transform: translate(0, -50%);
}
.embed-responsive .embed-responsive-21by9 {
  border: none;
}

@media screen and (max-width: 720px) {
  .video-container img {
    top: 0;
    transform: translate(0, 0);
  }
}
