.doNotDisplay {
  transform: translateY(-200%);
  /* display: none; */
  /* position: absolute; */
}
.top {
  /* transform: translateY(-30%); */
  top: 0;
  position: fixed;
  height: 100vh;
  animation-name: extendit;
  animation-duration: 0.5s;
}
.navigation-container {
  width: 20rem;
  position: fixed;
  padding: 0;
  padding-top: 3rem;
  padding-bottom: 1rem;
  padding-right: 6rem;
  transition: all 0.8s;
}
.navigation-container h2 {
  padding-left: 2.5rem;
  padding-bottom: 1.3rem;
}
.navigation-items li {
  padding-top: 0.5rem;
  padding-left: 2.5rem;

  padding-bottom: 0.5rem;
  transition: all 0.3s;
}
.navigation-items li a {
  /* background-color: red; */
  font-weight: 100;
  color: black;
  font-size: 1.1rem;
}

.navigation-items .current {
  /* background-color: red; */
  border-left: 4px solid #00a1cc;
  padding-left: 3rem;
}

.navigation-items .current a {
  font-weight: 600;
}

@keyframes extendit {
  from {
    top: 20%;
    height: 50vh;
  }
  to {
    top: 0;
    height: 100vh;
  }
}

.userprofile-navigation-container {
  display: inline-block;
  width: 24rem;
  background: #e7e9ec;
  padding: 0;

  padding-bottom: 1rem;
  padding-right: 6rem;
  transition: all 0.8s;
}

.userprofile-navigation-items ul {
  padding-top: 1.5rem;
}

.userprofile-navigation-items ul li {
  padding-top: 0.5rem;
  padding-left: 2.5rem;

  padding-bottom: 0.5rem;
  transition: all 0.3s;
}

.userprofile-navigation-items li button {
  /* background-color: red; */
  font-weight: 100;
  color: black;
  font-size: 1.1rem;
  border: none;
  outline: none;
  background: #e7e9ec;
}

.userprofile-navigation-items .current {
  border-left: 4px solid #00a1cc;

  padding-left: 3rem;
}

.userprofile-navigation-items .current button {
  font-weight: 600;
}

@media screen and (max-width: 992px) {
  .navigation-container {
    display: none;
  }
}
