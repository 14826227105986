.aboutus-section-heading {
  margin-bottom: 2.85rem;
  font-weight: 600;
  font-size: 2.2rem;
  color: #00a1cc;
  text-align: center;
  text-transform: uppercase;
}
.aboutus-section-subtitle {
  display: block;
  margin-bottom: 2rem;
  font-weight: 400;
  font-size: 1.5rem;
  color: #00a1cc;
  text-align: center;
  text-transform: uppercase;
}

.aboutus-section-text {
  display: block;
  text-align: justify;
  margin: 0 25px;
  margin-bottom: 2rem;
  font-size: 1.1rem;
  line-height: 2rem;
}
.aboutus-member-container {
  position: relative;
}

.aboutus-section-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.aboutus-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media screen and (max-width: 768px) {
}
