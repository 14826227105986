.myfooter {
  padding-top: 7rem;
  padding-bottom: 7rem;
  background-color: rgb(34, 30, 30);

  color: aliceblue;
}

.footer-paragraph {
  line-height: 1.5rem;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.5);
}
/* ul {
  margin: 0;
  padding: 0;
} */
.quicklinks-list ul li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.col-lg-4 {
  margin-bottom: 2rem;
}
.col-lg-2 {
  margin-bottom: 2rem;
}
.col-lg-2 ul li a {
  text-decoration: none;
  font-size: 16px;
  line-height: 2rem;
  color: rgba(255, 255, 255, 0.5);
  transition: 0.5s;
}

.col-lg-2 ul li a:hover {
  color: rgba(255, 255, 255, 1);
}

.footer-title {
  padding-bottom: 2rem;
}

i.icon.large {
  display: block;
}

.col-lg-2 a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
}

.col-lg-2 a:hover {
  color: rgba(255, 255, 255, 1);
}
.col-2 a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
}

.col-2 a:hover {
  color: rgba(255, 255, 255, 1);
}

.footer-border {
  margin-top: 5rem;
  margin-bottom: 3rem;
  border-top: 1px solid;
  border-color: rgba(255, 255, 255, 0.246);
}
.footer-paragraph-copyright {
  line-height: 1.5rem;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}
.sra-container {
  position: relative;
  padding-bottom: 59.1%;
  height: auto;
  overflow: hidden;
}
.sra-iframe {
  border: 0px;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
}

@media screen and (max-width: 1000px) {
  .myfooter {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media screen and (max-width: 990px) {
  .footer-about-us {
    display: none;
  }
  .footer-logo {
    display: none;
  }
  .footer-border {
    margin-top: 2rem;
    margin-bottom: 2.5rem;
  }
  .footer-title {
    padding-bottom: 1.5rem;
  }
  .sra-container {
    padding-bottom: 25%;
  }
}
@media screen and (max-width: 800px) {
  .sra-container {
    padding-bottom: 40%;
  }
}
@media screen and (max-width: 580px) {
  .sra-container {
    padding-bottom: 59.1%;
  }
}
